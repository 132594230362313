@if (formInputs.length) {
  <div class="col-12 mt-5">
    <h3>Please enter the extra information below</h3>
    <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
      @for (input of formInputs; track input.id) {
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText [id]="input.name" [formControlName]="input.name" />
            <label [htmlFor]="input.name">{{input.name}}</label>
            @if (input.required) {
              <small class="p-error ml-3">This field is required.</small>
            }
          </span>
        </div>
      }
    </form>
  </div>
}

<div class="grid">
  @if (instrument && grade) {
    <p-card header="Instrument" class="w-full m-3">
      <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
        <img class="shadow-2 block xl:block mx-auto border-round"
             [src]="'images/instruments/' + (instrument.Image || instrument.InstrumentName.toLowerCase()) + '.webp'"
             [alt]="instrument.InstrumentName"
             height="70"
             width="26"/>
        <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
          <div class="text-2xl font-bold text-900">{{ instrument.InstrumentName }} &mdash; {{ grade.Grade }}</div>
          <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
            <span class="text-2xl font-semibold">
              {{grade.DownPay + grade.DownMaint | currency}}
            </span>
          </div>
        </div>
      </div>
    </p-card>
  }
  @if (accessories?.length) {
    <p-card header="Options & Accessories" class="w-full m-3">
      <p-dataView #dv [value]="accessories" [lazy]="true">
        <ng-template let-accs pTemplate="listItem">
          @for (acc of accs; track acc.Id; let first = $first) {
            <div class="col-12">
              <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
                <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
                     [src]="(acc.Sku | accessoryImage | async)"
                     [alt]="acc.AccessoryDesc" />
                <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                  <div class="text-2xl font-bold text-900">{{ acc.AccessoryDesc }}</div>
                  <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                    <span class="text-2xl font-semibold">
                      {{acc.Price | currency}}
                    </span>
                    <span>
                      Qty: 1
                    </span>
                  </div>
                </div>
              </div>
            </div>
          }
        </ng-template>
      </p-dataView>
    </p-card>
  }
  @if (maintenance?.length) {
    <p-card header="Maintenance" class="w-full m-3">
      <p-dataView #dv [value]="maintenance" [lazy]="true">
        <ng-template let-maints pTemplate="listItem">
          @for (maint of maints; track maint.Id; let first = $first) {
            <div class="col-12">
              <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
                <!-- <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="maint.Topic" [alt]="maint.AccessoryDesc" /> -->
                <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                  <div class="text-2xl font-bold text-900">{{ maint.AccessoryDesc }}</div>
                  <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                    <span class="text-2xl font-semibold">
                      {{maint.Price | currency}}
                    </span>
                    <!-- <span>
                      Qty: 1
                    </span> -->
                  </div>
                </div>
              </div>
            </div>
          }
        </ng-template>
      </p-dataView>
    </p-card>
  }
  @if (delivery) {
    <p-card header="Delivery" class="w-full m-3">
      <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
        <!-- <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="delivery.Topic" [alt]="delivery.AccessoryDesc" /> -->
        <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
          <div class="text-2xl font-bold text-900">{{ delivery.AccessoryDesc }}</div>
          <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
            <span class="text-2xl font-semibold">
              {{delivery.Price | currency}}
            </span>
          </div>
        </div>
      </div>
    </p-card>
  }
</div>
<div class="text-right text-2xl font-semibold pr-3">Items & Down Payment: {{totals.items | currency}}</div>
<div class="text-right text-2xl font-semibold pr-3">Shipping: {{totals.shipping | currency}}</div>
<div class="text-right text-2xl font-semibold pr-3">Tax: {{totals.tax | currency}}</div>
<p-divider></p-divider>
<div class="text-right text-3xl font-semibold pr-3 mb-2">Total: {{totals.total | currency}}</div>
<div class="text-right text-3xl font-semibold pr-3">
  <p-button (onClick)="onCompleteClick()" [loading]="isProcessing" [disabled]="isProcessing || form.invalid" label="CHECKOUT"></p-button>
</div>
<p-dialog header="Error" [(visible)]="showError">
  <p>
    Your payment was declined.
  </p>
</p-dialog>
