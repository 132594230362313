import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, Category, FindAllResult, UpdateCateogryDto, SubCategory, UpdateSubCateogryDto } from '../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminCategoryService extends GenericSearchService<Category> {
  http = inject(HttpClient);
  ngxAimService = inject(NgxAimService);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/category`;

  constructor(private _httpErrorService: HttpErrorService) {
    super(_httpErrorService, 'Category');
  }

  sync(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/sync`, {});
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<Category>> {
    return this.http.post<FindAllResult<Category>>(`${this.baseUrl}/all`, dto);
  }

  findOne(CategoryNumber: number): Observable<Category> {
    return this.http.get<Category>(`${this.baseUrl}/category/${CategoryNumber}`);
  }

  save(dto: UpdateCateogryDto): Observable<Category> {
    return this.http.post<Category>(`${this.baseUrl}`, dto);
  }

  findOneSub(CategoryNumber: number, SubNumber: number): Observable<SubCategory> {
    return this.http.get<SubCategory>(`${this.baseUrl}/sub-category/${CategoryNumber}/${SubNumber}`);
  }

  saveSub(dto: UpdateSubCateogryDto): Observable<SubCategory> {
    return this.http.post<SubCategory>(`${this.baseUrl}/sub-category`, dto);
  }
}
