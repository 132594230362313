@if (item) {
  <main class="flex gap-3 flex-wrap justify-content-center">
    <div class="flex gap-3 flex-wrap justify-content-center">
      <ngx-aim-product-galleria [images]="item.images"></ngx-aim-product-galleria>
      <div class="mt-2 product-info">
        <h1>{{item.SETitle}}</h1>
        <p>{{item.Model}}</p>
        @if (isRental) {
          <ngx-aim-rental-price [itemPk]="item.Pk"></ngx-aim-rental-price>
        }
        @else {
          <h2>{{item | itemPrice | currency}}</h2>
        }
        @if (showBuyButton) {
          <p-button (onClick)="onProductActionClick(item)" [disabled]="item.AvailableQuantity <= 0">{{isRental ? 'RENT' : 'ADD TO CART'}}</p-button>
        }
        <div [innerHTML]="item.AvailableQuantity | outOfStock"></div>
        <p>{{item.Summary}}</p>
        <ul>
          @for (attribute of attributes; track $index) {
            <li>
              {{attribute.Name}}
              <ul>
                @for (value of attribute.Values; track $index) {
                  <li>{{value}}</li>
                }
              </ul>
            </li>
          }
        </ul>
      </div>
    </div>
    <p [innerHTML]="item.WebDescription"></p>
  </main>
}
@else {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
