import { Component, inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NonNullableFormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { NgxAimAdminPrimengModule } from '../../../ngx-aim-admin.primeng.module';
import { TrueFalseText } from '../../../../../types';
import { ContractFormInput } from '../../../../../models';
import { HttpErrorService, UtilsService } from '../../../../../services';
import { NgxAimAdminContractsService } from '../../ngx-aim-admin-contracts.service';

@Component({
  selector: 'ngx-aim-admin-edit-contract-form-input',
  standalone: true,
  imports: [ReactiveFormsModule, NgxAimAdminPrimengModule],
  templateUrl: './ngx-aim-admin-edit-contract-form-input.component.html',
  styleUrls: ['./ngx-aim-admin-edit-contract-form-input.component.scss', '../../../admin.scss'],
  providers: [DatePipe]
})
export class NgxAimAdminEditContractFormInputComponent implements OnInit {
  entityService = inject(NgxAimAdminContractsService);
  fb = inject(NonNullableFormBuilder);
  config = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  messageService = inject(MessageService);
  httpErrorService = inject(HttpErrorService);
  utils = inject(UtilsService);
  datePipe = inject(DatePipe);

  dropDownOptions: TrueFalseText[] = ['True', 'False'];

  form = this.fb.group({
    id: this.fb.control<number>(0),
    name: this.fb.control<string>(''),
    required: this.fb.control<TrueFalseText>('False'),
    forType: this.fb.control<string>(''),
  });

  metaForm = this.fb.group({
    createDate: [{ value: '', disabled: true }],
    updateDate: [{ value: '', disabled: true }],
  });

  isLoading = false;
  isSaving = false;

  ngOnInit() {
    if (typeof this.config.data === 'number') {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve form input')
    });
  }

  setFormData(entity: ContractFormInput) {
    this.isLoading = false;
    this.form.patchValue({
      ...entity,
      required: this.utils.convertBooleanToTrueFalseText(entity.required),
    });

    this.metaForm.patchValue({
      createDate: this.datePipe.transform(new Date(entity.createDate), 'short')!,
      updateDate: this.datePipe.transform(new Date(entity.updateDate), 'short')!,
    });
  }

  onSave() {
    this.isSaving = true;
    const dto: Partial<ContractFormInput> = {
      ...this.form.value,
      required: this.utils.convertTrueFalseTextToBoolean(this.form.controls.required.value),
    }
    this.entityService.save(dto).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save form input')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: ContractFormInput) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Form input saved',
      key: 'app-toast'
    });
  }
}
