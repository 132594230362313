import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../../ngx-aim.service';
import { Observable } from 'rxjs';
import { CarouselSlide } from '../../models';
import { CarouselName } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class NgxAimCarouselService {
  http = inject(HttpClient);
  ngxAimService = inject(NgxAimService);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/carousel-slide`;

  getSlides(carouselName: CarouselName): Observable<CarouselSlide[]> {
    return this.http.get<CarouselSlide[]>(`${this.baseUrl}/carousel/${carouselName}`);
  }
}
