import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { Observable } from 'rxjs';
import { AccessoryImage, ItemForDisplay } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  http = inject(HttpClient);
  ngxAimService = inject(NgxAimService);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/items`;

  findOne(id: number): Observable<ItemForDisplay> {
    return this.http.get<ItemForDisplay>(`${this.baseUrl}/${id}`);
  }

  getItemImageBySku(Sku: string): Observable<AccessoryImage> {
    return this.http.get<AccessoryImage>(`${this.baseUrl}/image-by-sku/${Sku}`);
  }
}
