import { DestroyRef, QueryList, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { MessageService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { AppStateService, AuthenticationService, CustomerService, HttpErrorService, PaymentService } from '../../services';
import { CheckoutTabsDisabled, CustomerHistoryGeneric, CustomerHistoryGenericPaymentDto, UpdateCCTokenResponse } from '../../models';
import { AddressType, CheckoutTabNames, CheckoutType, PaymentStatus, PaymentType, PublicRoutes, TransactionType } from '../../enums';
import { AccordionTab, AccordionTabOpenEvent } from 'primeng/accordion';
import { MakePaymentCheckoutService } from '../../services/make-payment-checkout.service';

/** when extending this class be sure to implement ngOnInit,
 * @ViewChild('paymentForm', { read: ViewContainerRef })
 * override paymentFormRef!: ViewContainerRef; 
 * 
 * ViewChildren('accordionTab')
*  override accordionTabs!: QueryList<AccordionTab>;
 */
export abstract class NgxAimMakePaymentClass {
  // ngOnInit() {
  //   this.init();
  // }

  // ngAfterViewInit(): void {
  //   this.afterViewInit();
  // }

  route = inject(ActivatedRoute);
  router = inject(Router);
  appState = inject(AppStateService);
  customerService = inject(CustomerService);
  paymentService = inject(PaymentService);
  httpErrorService = inject(HttpErrorService);
  messageService = inject(MessageService);
  fb = inject(FormBuilder);
  checkoutService = inject(MakePaymentCheckoutService);
  authService = inject(AuthenticationService);

  paymentFormRef!: ViewContainerRef;
  accordionTabs!: QueryList<AccordionTab>;

  tabs = CheckoutTabNames;
  tabIndex$ = this.checkoutService.tabIndex$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  tabsDisabled$ = this.checkoutService.tabsDisabed$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  params$ = this.route.params.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  form = this.fb.group({
    paymentAmount: this.fb.control<number | null>(null),
    UpdateCc: this.fb.control<boolean>(false),
  });

  invoice: CustomerHistoryGeneric | undefined;
  destroyRef = inject(DestroyRef);
  routes = PublicRoutes;
  addressType = AddressType;
  checkoutType = CheckoutType.PAYMENT;
  tabsDisabled: CheckoutTabsDisabled = {};
  accountNumber = this.authService.getCurrentUser()?.aimId || '';
  isLoading = false;
  isDisabled = true;
  isDialogVisible = false;

  constructor() {
    this.invoice = this.router.getCurrentNavigation()?.extras.state as CustomerHistoryGeneric;
    if (!this.invoice) {
      this.router.navigate([PublicRoutes.CUSTOMER, PublicRoutes.OPEN_INVOICES]);
    }
  }

  init() {
    this.initPaymentForm(this.appState.paymentType);

    this.tabsDisabled$.subscribe((tabs) => {
      this.tabsDisabled = tabs;
    });
  }

  afterViewInit() {
    this.checkoutService.tabs = this.accordionTabs.map((tab, i) => ({ header: tab.header!, index: i }));
  }

  initPaymentForm(type: PaymentType) {
    this.paymentService.cardData.type = type;
    switch (type) {
      case PaymentType.AUTHORIZENET: {
        import('../payment/authorizenet/ngx-aim-authorizenet.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimAuthorizenetComponent);
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
      case PaymentType.GLOBALPAY:
      case PaymentType.GLOBALPAY_AUTH_ONLY: {
        import('../payment/globalpay/ngx-aim-globalpay.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimGlobalpayComponent);
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
      case PaymentType.TSYS_AUTH_ONLY: {
        import('../payment/tsys/ngx-aim-tsys.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimTsysComponent);
          comp.instance.transactionType = TransactionType.CONTRACT;
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
    }
  }

  onCardSuccess() {
    this.isLoading = false;
    this.isDisabled = false;
  }

  onTabOpen($event: AccordionTabOpenEvent) {
    this.checkoutService.setTabIndex($event.index);
  }

  onSubmitClick() {
    this.isLoading = true;
    if (!this.invoice) return;
    const dto: CustomerHistoryGenericPaymentDto = {
      ...this.invoice,
      paymentAmount: +this.form.controls.paymentAmount.value!,
      UpdateCc: this.form.controls.UpdateCc.value || false,
    }
    this.customerService.makePayment(this.paymentService.cardData, dto).subscribe({
      next: (res) => this.onPaymentSuccess(res),
      error: (err) => this.onFailure(err),
    });
  }

  onPaymentSuccess(res: UpdateCCTokenResponse) {
    this.isLoading = false;
    if (res.paymentStatus === PaymentStatus.APPROVED) {
      this.isDialogVisible = true;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Could not make payment',
        key: 'app-toast'
      });
    }
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not make payment');
  }

  reset() {
    this.isDialogVisible = false
    this.checkoutService.reset();
  }
}