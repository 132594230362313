import { inject, Injectable } from '@angular/core';
import { Cart, ItemForDisplay, ItemInventoryType } from '../models';
import { AppStateService } from './app-state.service';
import { BehaviorSubject } from 'rxjs';
import { ItemPricePipe } from '../pipes';

const CART_NAME = 'aim-cart';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  appStateService = inject(AppStateService);
  itemPricePipe = new ItemPricePipe();

  private cartSubject = new BehaviorSubject<Cart>({ items: [] });
  public cart$ = this.cartSubject.asObservable();
  public getCart(): Cart {
    return this.cartSubject.value;
  }

  private cartTotalSubject = new BehaviorSubject<number>(0);
  public cartTotal$ = this.cartTotalSubject.asObservable();
  public getCartTotal(): number {
    return this.cartTotalSubject.value;
  }

  private cartHasSerializedItem = false;
  public hasCartGotSerializedItem(): boolean {
    return this.cartHasSerializedItem;
  }

  private cartHasAccessoryItem = false;
  public hasCartGotAccessoryItem(): boolean {
    return this.cartHasAccessoryItem;
  }

  constructor() {
    this.getCartFromStorage();
  }

  private getCartFromStorage() {
    if (this.appStateService.isBrowser) {
      const cartFromStorage = localStorage.getItem(CART_NAME);
      if (cartFromStorage) {
        const cart: Cart = JSON.parse(cartFromStorage);
        cart.items = cart.items?.filter(item => item.cartQty !== 0);
        this.updateCart(cart);
      }
    }
  }

  private saveCart() {
    localStorage.setItem(CART_NAME, JSON.stringify(this.cartSubject.value));
  }

  private createCart() {
    this.cartSubject.next({
      createdAt: new Date().toISOString(),
      items: [],
    });
  }

  private updateCart(cart: Cart) {
    this.cartSubject.next(cart);
    this.saveCart();
    this.calcCartTotal();
    this.cartHasSerializedItem = cart.items.some((item) => item.InventoryType === ItemInventoryType.SERIALIZED);
    this.cartHasAccessoryItem = cart.items.some((item) => item.InventoryType === ItemInventoryType.ACCESSORY);
  }

  public updateItemCartQuantity(item: ItemForDisplay, quantity = 1) {
    if (!this.cartSubject.value.createdAt) {
      this.createCart();
    }

    const cart = this.cartSubject.value;
    const itemInCart = cart.items.find(i => i.Pk === item.Pk);
    if (!itemInCart) {
      cart.items.push({
        ...item,
        cartQty: quantity,
      });
    } else {
      itemInCart.cartQty = quantity;
    }

    this.updateCart(cart);
  }

  public removeItemFromCart(item: ItemForDisplay) {
    const cart = this.cartSubject.value;
    const items = cart.items?.filter(i => i.Pk !== item.Pk);
    cart.items = items;
    this.updateCart(cart);
  }

  private calcCartTotal() {
    const total = this.cartSubject.value.items?.reduce((acc, item) => {
      return acc + (this.itemPricePipe.transform(item) * item.cartQty!)
    }, 0) || 0;

    this.cartTotalSubject.next(total);
  }

  public reset() {
    localStorage.removeItem(CART_NAME);
    this.cartSubject.next({ items: [] });
    this.cartTotalSubject.next(0);
    this.cartHasAccessoryItem = false;
    this.cartHasSerializedItem = false;
  }
}
