import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStateService, UserService } from '../../services';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimVerifyEmailClass {
  // ngOnInit() {
  //   this.init();
  // }

  routes = PublicRoutes;
  route = inject(ActivatedRoute);
  appState = inject(AppStateService);
  userService = inject(UserService);

  token: string | undefined;
  isVerifying = false;
  isVerified = false;
  error = '';

  init() {
    if (this.appState.isBrowser) {
      this.getToken();
    }
  }

  getToken() {
    if (this.appState.isPlatformBrowser) {
      this.route.queryParams.subscribe(params => {
        this.token = params['token'];
        if (this.token) {
          this.verify();
        } else {
          this.error = 'Invalid verify link';
          this.isVerified = true;
        }
      });
    }
  }

  verify() {
    if (!this.token) {
      return;
    }

    this.isVerifying = true;
    this.userService.verify(this.token).subscribe({
      next: () => {
        this.isVerifying = false;
        this.isVerified = true;
      },
      error: (err) => {
        this.error = err.text || err.error?.message || err.error?.error || err.message || 'Unkown error';
        this.isVerifying = false;
      }
    });
  }
}