import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, EMPTY } from 'rxjs';
import { AuthenticationService } from '../services';

export const unauthorizedInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthenticationService);
  return next(req).pipe(catchError(error => {
    if (error.error?.message === 'TokenExpiredError') {
      authService.logout();
      authService.showSessionExpiredMessage();
      return EMPTY
    }
    throw error;
  }));
};
