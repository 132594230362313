<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Id" formControlName="Id" />
        <label htmlFor="Id">Id</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="ShortDesc" formControlName="ShortDesc" />
        <label htmlFor="ShortDesc">Short Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Desc" formControlName="Desc" />
        <label htmlFor="Desc">Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="isDefault" formControlName="isDefault" />
        <label htmlFor="isDefault">Is Default</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Active" formControlName="Active" />
        <label htmlFor="Active">Active</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="InStorePickup" formControlName="InStorePickup" />
        <label htmlFor="InStorePickup">In Store Pickup</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="webName" formControlName="webName" />
        <label htmlFor="webName">Web Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="phone" formControlName="phone" />
        <label htmlFor="phone">Phone</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="phoneUrl" formControlName="phoneUrl" />
        <label htmlFor="phoneUrl">Phone URL</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="email" formControlName="email" />
        <label htmlFor="email">Email</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="address1" formControlName="address1" />
        <label htmlFor="address1">Address 1</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="address2" formControlName="address2" />
        <label htmlFor="address2">Address 2</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="city" formControlName="city" />
        <label htmlFor="city">City</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="state" formControlName="state" />
        <label htmlFor="state">State</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="postalCode" formControlName="postalCode" />
        <label htmlFor="postalCode">Postal Code</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Latitude" formControlName="Latitude" />
        <label htmlFor="Latitude">Latitude</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Longitude" formControlName="Longitude" />
        <label htmlFor="Longitude">Longitude</label>
      </span>
    </div>
    <div class="field col-12">
      <span class="p-float-label">
        <span style="color: gray;">Hours</span>
        <quill-editor class="editor" formControlName="hours"></quill-editor>
      </span>
    </div>
    <div class="field col-12">
      <span class="p-float-label">
        <span style="color: gray;">Extra Info</span>
        <quill-editor class="editor" formControlName="extraInfo"></quill-editor>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-save"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSaveClick()">
    </p-button>
    <p-button label="Set As Default"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid || !!this.form.controls.isDefault.value"
              (onClick)="onSetDefault()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
  <p-divider align="left">
    <i class="pi pi-info-circle mr-2"></i>
    <b>Metadata</b>
  </p-divider>
</div>
<div class="col-12">
  <form [formGroup]="metaForm" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="createDate" formControlName="createDate" />
        <label htmlFor="createDate">Created</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="updateDate" formControlName="updateDate" />
        <label htmlFor="updateDate">Updated</label>
      </span>
    </div>
  </form>
</div>
@if (isLoading) {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
