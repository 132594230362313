import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  breakpointObserver = inject(BreakpointObserver);

  public isMobile$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(
      map(breakpointState => breakpointState.matches)
    );

  public isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .pipe(
      map(breakpointState => breakpointState.matches)
    );

  private leftSidebarVisibleSubject = new BehaviorSubject<boolean>(false);
  public leftSidebarVisible$ = this.leftSidebarVisibleSubject.asObservable();
  public set leftSidebarVisible(isVisible: boolean) {
    this.leftSidebarVisibleSubject.next(isVisible);
  }
}
