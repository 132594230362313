import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { NgxAimAdminPrimengModule } from '../../../ngx-aim-admin.primeng.module';
import { AppUploadEvent, SubCategory, UpdateSubCateogryDto } from '../../../../../models';
import { HttpErrorService, UtilsService } from '../../../../../services';
import { TrueFalseText } from '../../../../../types';
import { NgxAimAdminCategoryService } from '../../ngx-aim-admin-category.service';

interface STRSubCatSpecsFormControls {
  name: FormControl<string>;
  value: FormControl<string>;
}

@Component({
  selector: 'ngx-aim-admin-edit-sub-category',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FileUploadModule,
    NgxAimAdminPrimengModule,
  ],
  templateUrl: './ngx-aim-admin-edit-sub-category.component.html',
  styleUrls: ['./ngx-aim-admin-edit-sub-category.component.scss', '../../../admin.scss'],
})
export class NgxAimAdminEditSubCategoryComponent implements OnInit {
  entityService = inject(NgxAimAdminCategoryService);
  fb = inject(FormBuilder);
  config = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  messageService = inject(MessageService);
  httpErrorService = inject(HttpErrorService);
  utils = inject(UtilsService);

  headers: any;
  dropDownOptions: TrueFalseText[] = ['True', 'False'];

  form = this.fb.group({
    SubNumber: this.fb.nonNullable.control<number>(0),
    Description: this.fb.nonNullable.control<string>({ value: '', disabled: true }),
    showOnMainNav: this.fb.nonNullable.control<TrueFalseText>('False'),
    thumbSrc: this.fb.control<string | null>(null),
    webTitle: this.fb.nonNullable.control<string>('', [Validators.required]),
    shortDescription: this.fb.nonNullable.control<string>(''),
    longDescription: this.fb.nonNullable.control<string>(''),
    strSpecs: this.fb.nonNullable.array<FormGroup<STRSubCatSpecsFormControls>>([]),
  });

  isLoading = false;
  isSaving = false;

  ngOnInit() {
    if (this.config.data) {
      this.getEntity(this.config.data.CategoryNumber, this.config.data.SubNumber);
    }
  }

  getEntity(CategoryNumber: number, SubNumber: number) {
    this.isLoading = true;
    this.entityService.findOneSub(CategoryNumber, SubNumber).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve sub category')
    });
  }

  setFormData(entity: SubCategory) {
    this.strSpecs.clear();
    this.form.patchValue({
      ...entity,
      showOnMainNav: this.utils.convertBooleanToTrueFalseText(entity.showOnMainNav),
    });
    entity.strSpecs?.forEach((strSpec) => {
      this.addStrSpec(strSpec.name, strSpec.value);
    });

    this.headers = { CategoryNumber: this.config.data.CategoryNumber, SubNumber: entity.SubNumber };
    this.isLoading = false;
  }

  onSave() {
    this.isSaving = true;
    const dto: UpdateSubCateogryDto = {
      CategoryNumber: this.config.data.CategoryNumber,
      SubNumber: this.form.controls.SubNumber.value,
      showOnMainNav: this.utils.convertTrueFalseTextToBoolean(this.form.controls.showOnMainNav.value),
      webTitle: this.form.controls.webTitle.value,
      shortDescription: this.form.controls.shortDescription.value,
      longDescription: this.form.controls.longDescription.value,
      strSpecs: this.strSpecs.getRawValue(),
    }

    this.entityService.saveSub(dto).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save sub category ')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: SubCategory) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Sub category saved',
      key: 'app-toast'
    });
  }

  onUpload(event: AppUploadEvent) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image Uploaded', key: 'app-toast' });
    this.form.patchValue({ thumbSrc: event.originalEvent.body.url });
  }

  onUploadError(event: any) {
    this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Image could not be uploaded', key: 'app-toast' });
  }

  addStrSpec(name: string = '', value: string = '') {
    const strSpec = this.fb.nonNullable.group<STRSubCatSpecsFormControls>({
      name: this.fb.nonNullable.control<string>(name, [Validators.required]),
      value: this.fb.nonNullable.control<string>(value, [Validators.required]),
    });

    this.strSpecs.push(strSpec);
  }

  removeStrSpec(index: number) {
    this.strSpecs.removeAt(index);
  }

  get strSpecs() {
    return this.form.controls.strSpecs
  }
}
