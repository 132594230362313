export interface Location {
  Id: number;
  Desc: string;
  ShortDesc: string;
  Region: string;
  Latitude: number;
  Longitude: number;
  InStorePickup: string;
  isDefault: boolean;
  Active: boolean;
  createDate: string;
  updateDate: string;
  webName: string;
  phone: string;
  phoneUrl: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  tax: number;
  hours: string;
  extraInfo: string;
}

export interface SaveLocationDto {
  Id: number | null;
  Desc: string | null;
  ShortDesc: string | null;
  Region: string | null;
  Latitude: number | null;
  Longitude: number | null;
  InStorePickup: string | null;
  isDefault: boolean | null;
  Active: boolean | null;
  createDate: string | null;
  updateDate: string | null;
  webName: string | null;
  phone: string | null;
  phoneUrl: string | null;
  email: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  tax: number | null;
  hours: string | null;
  extraInfo: string | null;
}

export const EMPTY_LOCATION: Location = {
  Id: 0,
  Desc: '',
  ShortDesc: '',
  Region: '',
  Latitude: 0,
  Longitude: 0,
  InStorePickup: '',
  isDefault: true,
  Active: true,
  createDate: '',
  updateDate: '',
  webName: '',
  phone: '',
  phoneUrl: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  tax: 0,
  hours: '',
  extraInfo: '',
}
