import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import { ContactUsMessageDto, CreateAccountDto, ResetPasswordDto } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  ngxAimService = inject(NgxAimService);
  http = inject(HttpClient);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/users`;

  /** returns the users email */
  createAccount(dto: CreateAccountDto): Observable<{ email: string }> {
    return this.http.post<{ email: string }>(`${this.baseUrl}/create-account`, dto);
  }

  verify(token: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/verify-email`, { token });
  }

  /** This will always return success even if the email address isn't registered.
  *  We don't want to leak registered addresses */
  sendPasswordResetEmail(email: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/send-reset-email`, { email });
  }

  resetPassword(dto: ResetPasswordDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/reset-password`, dto);
  }

  sendContactUsMessage(dto: ContactUsMessageDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/contact-us`, dto);
  }
}
