import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ItemForDisplay } from '../../../models';
import { NgxAimProductPriceClass } from './ngx-aim-product-price.class';
import { CurrencyPipe } from '@angular/common';
import { ItemPricePipe } from '../../../pipes';

@Component({
  selector: 'ngx-aim-product-price',
  standalone: true,
  imports: [DropdownModule, FormsModule, ProgressSpinnerModule, CurrencyPipe, ItemPricePipe],
  templateUrl: './ngx-aim-product-price.component.html',
  styleUrls: ['./ngx-aim-product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimProductPriceComponent extends NgxAimProductPriceClass implements OnInit {
  @Input({ required: true })
  override item!: ItemForDisplay;

  @Input({ required: true })
  override isRental!: boolean;

  ngOnInit() {
    this.init();
  }
}
