import { inject, Pipe, PipeTransform } from '@angular/core';
import { ItemService } from '../../services';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'accessoryImage',
  standalone: true,
  pure: true,
})
export class AccessoryImagePipe implements PipeTransform {
  itemService = inject(ItemService);

  transform(Sku: string): Observable<string> {
    return this.itemService.getItemImageBySku(Sku).pipe(
      map(res => {
        return res.thumbSrc
          ? `files/${res.thumbSrc}`
          : 'images/others/missing-image.png'
      })
    );
  }
}
