import { Component, inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { NgxAimAdminPrimengModule } from '../../ngx-aim-admin.primeng.module';
import { ControlsOf } from '../../../../types';
import { CustomerDefaults, SetCustomerDefaultDto } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { NgxAimAdminCustomerDefaultsService } from '../ngx-aim-admin-customer-defaults.service';

@Component({
  selector: 'ngx-aim-admin-edit-customer-default',
  standalone: true,
  imports: [ReactiveFormsModule, NgxAimAdminPrimengModule],
  templateUrl: './ngx-aim-admin-edit-customer-default.component.html',
  styleUrls: ['./ngx-aim-admin-edit-customer-default.component.scss', '../../admin.scss'],
  providers: [DatePipe],
})
export class NgxAimAdminEditCustomerDefaultComponent implements OnInit {
  entityService = inject(NgxAimAdminCustomerDefaultsService);
  fb = inject(FormBuilder);
  config = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  messageService = inject(MessageService);
  httpErrorService = inject(HttpErrorService);
  datePipe = inject(DatePipe);

  isLoading = false;
  isSaving = false;

  form = this.fb.group<ControlsOf<SetCustomerDefaultDto>>({
    Id: this.fb.control<number | null>(null),
    isDefault: this.fb.control<boolean | null>(false),
    Desc: this.fb.control<string | null>(''),
    ShortDesc: this.fb.control<string | null>(''),
  });

  metaForm = this.fb.group({
    createDate: [{ value: '', disabled: true }],
    updateDate: [{ value: '', disabled: true }],
  });

  ngOnInit() {
    this.form.disable();
    if (this.config.data) {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve customer default')
    });
  }

  setFormData(entity: CustomerDefaults) {
    this.isLoading = false;
    this.form.patchValue(entity);
    this.metaForm.patchValue({
      createDate: this.datePipe.transform(new Date(entity.createDate), 'short'),
      updateDate: this.datePipe.transform(new Date(entity.updateDate), 'short'),
    });
  }

  onSetDefault() {
    this.isSaving = true;
    this.entityService.setDefault(this.form.getRawValue()).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save customer default')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: CustomerDefaults) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Customer default saved',
      key: 'app-toast'
    });
  }
}
