import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { Observable } from 'rxjs';
import { WebCategory } from '../models';

@Injectable({
  providedIn: 'root'
})
export class WebCategoriesService {
  http = inject(HttpClient);
  ngxAimService = inject(NgxAimService);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/web-categories`;

  getCategories(): Observable<WebCategory[]> {
    return this.http.get<WebCategory[]>(this.baseUrl);
  }
}
