import { inject, Injectable } from '@angular/core';
import {
  CheckoutTab,
  CheckoutTabsDisabled,
  CheckoutTotals,
  Ffl,
  ItemInventoryType,
  ProcessOrderDto,
  ProcessOrderResponse,
  SaveAddressDto,
  UserAddress
} from '../models';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressType, CheckoutTabNames } from '../enums';
import { TaxAmountDto } from '../models/dto/tax.dto';
import { CartService } from './cart.service';
import { combineLatest } from 'rxjs';
import { NgxAimDeliveryOptionsService } from '../components/checkout/delivery-options/ngx-aim-delivery-options.service';
import { HttpErrorService } from './http-error.service';
import { GenericCheckoutService } from './generic-checkout.service';
import { PaymentService } from './payment.service';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService implements GenericCheckoutService {
  ngxAimService = inject(NgxAimService);
  http = inject(HttpClient);
  cartService = inject(CartService);
  deliveryService = inject(NgxAimDeliveryOptionsService);
  httpErrorService = inject(HttpErrorService);
  paymentService = inject(PaymentService);
  appState = inject(AppStateService);

  private baseUrl = this.ngxAimService.getApiUrl();

  public tabs: CheckoutTab[] = [];

  private tabIndexSubject = new BehaviorSubject<number>(0);
  public tabIndex$ = this.tabIndexSubject.asObservable();
  public setTabIndex(index: number) {
    this.tabIndexSubject.next(index);
  }
  public setTabIndexByHeader(header: CheckoutTabNames) {
    const tab = this.tabs.find((tab) => tab.header === header);
    if (tab) {
      this.tabIndexSubject.next(tab.index);
    } else {
      console.error(`unable to find tab header: ${header}`);
    }
  }

  private tabsDisabledSubject = new BehaviorSubject<CheckoutTabsDisabled>({
    [CheckoutTabNames.SHIPPING_ADDRESS]: false,
    [CheckoutTabNames.BILLING_ADDRESS]: true,
    [CheckoutTabNames.FFL_SELECTOR]: true,
    [CheckoutTabNames.DELIVERY_OPTIONS]: true,
    [CheckoutTabNames.DELIVERY_OPTIONS_SERIALIZED]: true,
    [CheckoutTabNames.PAYMENT]: true,
    [CheckoutTabNames.OVERVIEW]: true,
  });
  public tabsDisabed$ = this.tabsDisabledSubject.asObservable();
  public setTabIsDisabled(tabIndex: CheckoutTabNames, isDisabled: boolean) {
    const tabs = this.tabsDisabledSubject.value;
    tabs[tabIndex] = isDisabled;
    this.tabsDisabledSubject.next(tabs);
  }

  private isLoadingTotalsSubject = new BehaviorSubject<boolean>(false);
  public isLoadingTotals$ = this.isLoadingTotalsSubject.asObservable();
  private totalsSubject = new BehaviorSubject<CheckoutTotals>({
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  });
  public totals$ = this.totalsSubject.asObservable();

  calculateTotals = combineLatest([
    this.cartService.cartTotal$,
    this.deliveryService.selectedDeliveryOption$,
    this.deliveryService.selectedSerializedDeliveryOption$
  ]).subscribe(async ([
    cartTotal,
    deliveryOption,
    serializedDeliveryOption
  ]) => {
    this.isLoadingTotalsSubject.next(true);
    const shipping = deliveryOption.total_amount + serializedDeliveryOption.total_amount;
    const tax = await this.getTaxAmount(cartTotal + shipping);
    this.totalsSubject.next({
      items: cartTotal,
      shipping: shipping,
      tax: tax,
      total: cartTotal + shipping + tax
    });
    this.isLoadingTotalsSubject.next(false);
  });

  private userBillingAddressSubject = new BehaviorSubject<Partial<SaveAddressDto>>({});
  public userBillingAddress$ = this.userBillingAddressSubject.asObservable();
  public getUserBillingAddress() {
    return this.userBillingAddressSubject.value;
  }

  public selectedFfl: Partial<Ffl> = {};

  saveAddress(address: SaveAddressDto): Observable<void> {
    this.userBillingAddressSubject.next(address);
    return this.http.post<void>(`${this.baseUrl}/users/save-address`, address);
  }

  getAddress(type: AddressType): Observable<UserAddress> {
    return this.http.get<UserAddress>(`${this.baseUrl}/users/get-address`, {
      params: new HttpParams()
        .set('type', type)
    });
  }

  getTaxAmount(amount: number): Promise<number> {
    return new Promise<number>((resolve) => {
      this.http.post<TaxAmountDto>(`${this.baseUrl}/payment/tax`, { amount }).subscribe({
        next: (res) => resolve(res.amount),
        error: (err) => {
          if (this.appState.isBrowser) {
            this.httpErrorService.onHttpError(err, 'Could not calculate sales tax');
          }
          resolve(0);
        }
      });
    });
  }

  getDeliveryOptions(itemType: ItemInventoryType): void {
    return this.deliveryService.getDeliveryOptions(itemType, this.cartService.getCart().items, this.selectedFfl);
  }

  checkout(orderIdJwt?: string): Observable<ProcessOrderResponse> {
    const dto: ProcessOrderDto = {
      cart: this.cartService.getCart(),
      deliveryOption: this.deliveryService.getSelectedDeliveryOption().rate_id === '0' ? undefined : this.deliveryService.getSelectedDeliveryOption(),
      serializedDeliveryOption: this.deliveryService.getSelectedSerializedDeliveryOption().rate_id === '0' ? undefined : this.deliveryService.getSelectedSerializedDeliveryOption(),
      cardData: this.paymentService.cardData,
      orderIdJwt: orderIdJwt,
      ffl: this.selectedFfl,
    }
    return this.http.post<ProcessOrderResponse>(`${this.baseUrl}/orders/checkout`, dto);
  }

  public reset() {
    this.paymentService.reset();
    this.tabIndexSubject.next(0);
    this.tabsDisabledSubject.next({
      [CheckoutTabNames.SHIPPING_ADDRESS]: false,
      [CheckoutTabNames.BILLING_ADDRESS]: true,
      [CheckoutTabNames.FFL_SELECTOR]: true,
      [CheckoutTabNames.DELIVERY_OPTIONS]: true,
      [CheckoutTabNames.DELIVERY_OPTIONS_SERIALIZED]: true,
      [CheckoutTabNames.PAYMENT]: true,
      [CheckoutTabNames.OVERVIEW]: true,
    });

    this.totalsSubject.next({
      items: 0,
      shipping: 0,
      tax: 0,
      total: 0,
    });
  }
}
