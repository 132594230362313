import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalpayConfig } from './globalpay.model';

@Injectable({
  providedIn: 'root'
})
export class NgxAimGlobalpayService {
  ngxAimService = inject(NgxAimService);
  http = inject(HttpClient);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/payment/globalpay`;

  getConfig(): Observable<GlobalpayConfig> {
    return this.http.get<GlobalpayConfig>(`${this.baseUrl}/config`);
  }
}
