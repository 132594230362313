import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import { InitData } from '../models/init-data.model';
import { MenuItem } from 'primeng/api';
import { PaymentType, StoreType } from '../enums';
import { BusinessSettings, EMPTY_BUSINESS_SETTINGS, EMPTY_LOCATION, Location, PriceLevelTypes, UserInfo } from '../models';

/** Can be used to change the root category displayed to users
 * if userValue is a number it will change the root category
 * if userValue is a string it will not change the root category or ommit it in the providers array in app.config
 * {
     provide: ROOT_CATEGORY_ID,
     useValue: 0,
   },
 */
export const ROOT_CATEGORY_ID = new InjectionToken('ROOT_CATEGORY_ID');

export function initApp(appStateService: AppStateService, rootCategoryId?: number) {
  return () => {
    if (appStateService.isPlatformBrowser) {
      return Promise.all([appStateService.initApp(rootCategoryId), appStateService.initUser()]);
    }
    return Promise.all([appStateService.initApp(rootCategoryId)]);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}`;

  #isPlatformBrowser = false;
  public get isPlatformBrowser(): boolean {
    return this.#isPlatformBrowser;
  }

  #categories: MenuItem[] = [];
  private setCategories(categories: MenuItem[], rootCategoryId?: number) {
    if (typeof rootCategoryId === 'number') {
      const rootCategory = categories.find((cat) => cat.queryParams?.['category'] === rootCategoryId);
      if (rootCategory?.items?.length) {
        this.#categories = rootCategory.items;
      }
    } else {
      this.#categories = categories;
    }
  }
  public get categories(): MenuItem[] {
    return this.#categories;
  }

  #storeType: StoreType = StoreType.GENERIC;
  public get storeType(): StoreType {
    return this.#storeType;
  }

  #paymentType: PaymentType = PaymentType.NONE;
  public get paymentType(): PaymentType {
    return this.#paymentType;
  }

  #chargeStr: boolean = false;
  public get chargeStr(): boolean {
    return this.#chargeStr;
  }

  #inStorePickup: boolean = false;
  public get inStorePickup(): boolean {
    return this.#inStorePickup;
  }

  #guestCheckout: boolean = false;
  public get guestCheckout(): boolean {
    return this.#guestCheckout;
  }

  #businessSettings: BusinessSettings = EMPTY_BUSINESS_SETTINGS;
  public get businessInfo(): BusinessSettings {
    return this.#businessSettings;
  }

  #locations: Location[] = [];
  public getLocations(): Location[] {
    return this.#locations;
  }

  #defaultLocation: Location = EMPTY_LOCATION;
  public getDefaultLocation(): Location {
    return this.#defaultLocation;
  }

  #userPriceLevel: PriceLevelTypes = 'ActiveePrice';
  public getUserPriceLevel(): PriceLevelTypes {
    return this.#userPriceLevel;
  }
  public setUserPriceLevel(level: PriceLevelTypes) {
    this.#userPriceLevel = level;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngxAimService: NgxAimService,
    private http: HttpClient,
  ) {
    this.#isPlatformBrowser = isPlatformBrowser(this.platformId);
  }

  public get isBrowser(): boolean {
    return this.isPlatformBrowser;
  }

  public async initApp(rootCategoryId?: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.http.get<InitData>(`${this.baseUrl}/init-data`).subscribe({
        next: (initData) => {
          this.setCategories(initData.categories, rootCategoryId);
          this.#storeType = initData.storeType;
          this.#paymentType = initData.paymentType;
          this.#businessSettings = initData.businessSettings;
          this.#chargeStr = initData.chargeStr;
          this.#inStorePickup = initData.inStorePickup;
          this.#guestCheckout = initData.guestCheckout;
          this.#locations = initData.locations;
          this.#defaultLocation = initData.locations?.find((location) => location.isDefault) || EMPTY_LOCATION;
          resolve();
        },
        error: (err) => resolve(),
      });
    });
  }

  public async initUser() {
    return new Promise<void>((resolve) => {
      this.http.get<UserInfo>(`${this.baseUrl}/users/user-info`).subscribe({
        next: (userInfo) => {
          this.#userPriceLevel = userInfo.PriceLevel;
          resolve();
        },
        error: (err) => resolve(),
      });
    });
  }
}
