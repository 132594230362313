import { Component, DestroyRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { NgxAimAdminPrimengModule } from '../../ngx-aim-admin.primeng.module';
import { ControlsOf } from '../../../../types';
import { Location, SaveLocationDto } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { NgxAimAdminLocationsService } from '../ngx-aim-admin-locations.service';
import { QuillModule } from 'ngx-quill';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ngx-aim-admin-edit-location',
  standalone: true,
  imports: [ReactiveFormsModule, NgxAimAdminPrimengModule, QuillModule],
  templateUrl: './ngx-aim-admin-edit-location.component.html',
  styleUrls: ['./ngx-aim-admin-edit-location.component.scss', '../../admin.scss', '../../../../3rd-party/quill/quill.snow.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})
export class NgxAimAdminEditLocationComponent implements OnInit {
  entityService = inject(NgxAimAdminLocationsService);
  fb = inject(FormBuilder);
  config = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  messageService = inject(MessageService);
  httpErrorService = inject(HttpErrorService);
  datePipe = inject(DatePipe);
  destoryRef = inject(DestroyRef);

  isLoading = false;
  isSaving = false;

  form = this.fb.group<ControlsOf<SaveLocationDto>>({
    Id: this.fb.control<number | null>({ value: null, disabled: true }),
    Desc: this.fb.control<string | null>({ value: null, disabled: true }),
    ShortDesc: this.fb.control<string | null>({ value: null, disabled: true }),
    Region: this.fb.control<string | null>({ value: null, disabled: true }),
    Latitude: this.fb.control<number | null>(null),
    Longitude: this.fb.control<number | null>(null),
    InStorePickup: this.fb.control<string | null>({ value: null, disabled: true }),
    isDefault: this.fb.control<boolean | null>({ value: null, disabled: true }),
    Active: this.fb.control<boolean | null>({ value: null, disabled: true }),
    createDate: this.fb.control<string | null>({ value: null, disabled: true }),
    updateDate: this.fb.control<string | null>({ value: null, disabled: true }),
    webName: this.fb.control<string | null>(null),
    phone: this.fb.control<string | null>(null),
    phoneUrl: this.fb.control<string | null>({ value: null, disabled: true }),
    email: this.fb.control<string | null>(null),
    address1: this.fb.control<string | null>(null),
    address2: this.fb.control<string | null>(null),
    city: this.fb.control<string | null>(null),
    state: this.fb.control<string | null>(null),
    postalCode: this.fb.control<string | null>(null),
    tax: this.fb.control<number | null>(null),
    hours: this.fb.control<string | null>(null),
    extraInfo: this.fb.control<string | null>(null),
  });

  metaForm = this.fb.group({
    createDate: [{ value: '', disabled: true }],
    updateDate: [{ value: '', disabled: true }],
  });

  ngOnInit() {
    if (this.config.data) {
      this.getEntity(this.config.data);
    }
    this.initPhoneUrl();
  }

  initPhoneUrl() {
    this.form.controls.phone.valueChanges.pipe(takeUntilDestroyed(this.destoryRef)).subscribe((value) => {
      this.form.controls.phoneUrl.setValue(`tel:${value?.replace(/\D/g, '')}`);
    });
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve location')
    });
  }

  setFormData(entity: Location) {
    this.isLoading = false;
    this.form.patchValue(entity);
    this.metaForm.patchValue({
      createDate: this.datePipe.transform(new Date(entity.createDate), 'short'),
      updateDate: this.datePipe.transform(new Date(entity.updateDate), 'short'),
    });
  }

  onSetDefault() {
    this.isSaving = true;
    this.entityService.setDefault(this.form.getRawValue()).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save location')
    });
  }

  onSaveClick() {
    this.isSaving = true;
    this.entityService.save(this.form.getRawValue()).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save location')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: Location) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Location saved',
      key: 'app-toast'
    });
  }
}
