import { inject, Injectable } from '@angular/core';
import {
  CheckoutTabsDisabled,
  CheckoutTotals,
  ProcessOrderResponse,
  CheckoutTab,
  RentalData,
  SaveAddressDto,
  UserAddress,
  STRData,
  ProcessShortTermRentalDto
} from '../models';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressType, CheckoutTabNames } from '../enums';
import { TaxAmountDto } from '../models/dto/tax.dto';
import { HttpErrorService } from './http-error.service';
import { PaymentService } from './payment.service';

@Injectable({
  providedIn: 'root'
})
export class ShortTermRentalCheckoutService {
  ngxAimService = inject(NgxAimService);
  http = inject(HttpClient);
  httpErrorService = inject(HttpErrorService);
  paymentService = inject(PaymentService);

  private baseUrl = this.ngxAimService.getApiUrl();
  private rentalDataSubject = new BehaviorSubject<RentalData>({
    CategoryNumber: 0,
    SubNumber: 0,
    subCategory: {},
  });
  public rentalData$ = this.rentalDataSubject.asObservable();
  public setRentalData(rentalData: RentalData) {
    this.rentalDataSubject.next(rentalData);
    localStorage.setItem('rental', JSON.stringify(rentalData));
  }
  public getRentalData() {
    return this.rentalDataSubject.value;
  }

  public tabs: CheckoutTab[] = [];

  private tabIndexSubject = new BehaviorSubject<number>(0);
  public tabIndex$ = this.tabIndexSubject.asObservable();
  public setTabIndex(index: number) {
    this.tabIndexSubject.next(index);
  }
  public setTabIndexByHeader(header: CheckoutTabNames) {
    const tab = this.tabs.find((tab) => tab.header === header);
    if (tab) {
      this.tabIndexSubject.next(tab.index);
    } else {
      console.error(`unable to find tab header: ${header}`);
    }
  }

  private tabsDisabledSubject = new BehaviorSubject<CheckoutTabsDisabled>({
    [CheckoutTabNames.CHECK_AVAILABILITY]: false,
    [CheckoutTabNames.BILLING_ADDRESS]: true,
    [CheckoutTabNames.PAYMENT]: true,
    [CheckoutTabNames.OVERVIEW]: true,
  });
  public tabsDisabed$ = this.tabsDisabledSubject.asObservable();
  public setTabIsDisabled(tabIndex: CheckoutTabNames, isDisabled: boolean) {
    const tabs = this.tabsDisabledSubject.value;
    tabs[tabIndex] = isDisabled;
    this.tabsDisabledSubject.next(tabs);
  }

  private strDataSubject = new BehaviorSubject<STRData>({
    rate: {
      HeaderDesc: '',
      HeaderNotes: '',
      Desc: '',
      IntervalType: '',
      Group: '',
      Notes: '',
      Amt: 0,
      TimeInterval: 0,
    },
    PickupDate: new Date(),
    ReturnDate: new Date(),
    strAmount: {
      total: 0,
      IntervalType: '',
      length: 0,
      datePipeOption: 'short',
      rateAmt: 0,
    }
  });
  public strData$ = this.strDataSubject.asObservable();
  public setStrData(strData: STRData) {
    this.strDataSubject.next(strData);
    this.calculateTotals();
  }

  private isLoadingTotalsSubject = new BehaviorSubject<boolean>(false);
  public isLoadingTotals$ = this.isLoadingTotalsSubject.asObservable();
  private totalsSubject = new BehaviorSubject<CheckoutTotals>({
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  });
  public totals$ = this.totalsSubject.asObservable();

  async calculateTotals() {
    this.isLoadingTotalsSubject.next(true);
    const itemTotal = this.strDataSubject.value.strAmount?.total || 0;
    const tax = await this.getTaxAmount(itemTotal);
    const shipping = 0;

    this.totalsSubject.next({
      items: this.strDataSubject.value.strAmount?.total || 0,
      shipping: shipping,
      tax: tax,
      total: itemTotal + shipping + tax,
    });
    this.isLoadingTotalsSubject.next(false);
  }

  constructor() {
    if (typeof localStorage === 'undefined') {
      return;
    }

    const rawSavedCatSubCat = localStorage.getItem('rental');
    if (rawSavedCatSubCat) {
      const savedCatSubCat = JSON.parse(rawSavedCatSubCat) as RentalData;
      this.setRentalData(savedCatSubCat);
    }
  }

  saveAddress(address: SaveAddressDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/users/save-address`, address);
  }

  getAddress(type: AddressType): Observable<UserAddress> {
    return this.http.get<UserAddress>(`${this.baseUrl}/users/get-address`, {
      params: new HttpParams()
        .set('type', type)
    });
  }

  getTaxAmount(amount: number): Promise<number> {
    return new Promise<number>((resolve) => {
      this.http.post<TaxAmountDto>(`${this.baseUrl}/payment/tax`, { amount }).subscribe({
        next: (res) => resolve(res.amount),
        error: (err) => {
          this.httpErrorService.onHttpError(err, 'Could not calculate sales tax');
          resolve(0);
        }
      });
    });
  }

  checkout(): Observable<ProcessOrderResponse> {
    const rentalData = this.rentalDataSubject.value;
    const dto: ProcessShortTermRentalDto = {
      CategoryNumber: rentalData.CategoryNumber,
      SubNumber: rentalData.SubNumber,
      strData: this.strDataSubject.value,
      cardData: this.paymentService.cardData,
    }
    return this.http.post<ProcessOrderResponse>(`${this.baseUrl}/short-term-rental/checkout`, dto);
  }

  public reset() {
    this.paymentService.reset();
    localStorage.removeItem('rental');
    this.setRentalData({
      CategoryNumber: 0,
      SubNumber: 0,
      subCategory: {},
    });
    this.tabIndexSubject.next(0);
    this.tabsDisabledSubject.next({
      [CheckoutTabNames.CHECK_AVAILABILITY]: false,
      [CheckoutTabNames.BILLING_ADDRESS]: true,
      [CheckoutTabNames.PAYMENT]: true,
      [CheckoutTabNames.OVERVIEW]: true,
    });

    this.totalsSubject.next({
      items: 0,
      shipping: 0,
      tax: 0,
      total: 0,
    });
  }
}
