export enum CheckoutTabNames {
  SHIPPING_ADDRESS = 'Shipping Address',
  BILLING_ADDRESS = 'Billing Address',
  DELIVERY_OPTIONS = 'Delivery Options',
  DELIVERY_OPTIONS_SERIALIZED = 'FFL Delivery Options',
  PAYMENT = 'Payment',
  OVERVIEW = 'Overview',
  CHECK_AVAILABILITY = 'Check Availability',
  FFL_SELECTOR = 'Select FFL'
}

export enum CheckoutType {
  PURCHASE = 'purchase',
  SHORT_TERM_RENTAL = 'shortTermRental',
  CONTRACT = 'contract',
  PAYMENT = 'payment',
}
