import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { Observable } from 'rxjs';
import { ShipEngineAddressValidateResponse, ShipEngineAddressDto } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAddressService {
  http = inject(HttpClient);
  ngxAimService = inject(NgxAimService);

  verifyAddress(dto: ShipEngineAddressDto): Observable<ShipEngineAddressValidateResponse> {
    return this.http.post<ShipEngineAddressValidateResponse>(`${this.ngxAimService.getApiUrl()}/shipping/ship-engine/validate-address`, dto);
  }
}
