import { inject, Pipe, PipeTransform } from '@angular/core';
import { ItemForDisplay, ItemPriceLevels, PriceLevelTypes } from '../../models';
import { AppStateService } from '../../services';

@Pipe({
  name: 'itemPrice',
  standalone: true,
  pure: true,
})
export class ItemPricePipe implements PipeTransform {
  appStateService = inject(AppStateService);

  transform(item: ItemForDisplay): number {
    let price = item[this.getItemPriceProperty(this.appStateService.getUserPriceLevel())];
    if (price === 0) {
      price = item[this.getItemPriceProperty('ActiveePrice')];
    }
    return price;
  }

  getItemPriceProperty(priceLevel: PriceLevelTypes): keyof ItemPriceLevels {
    switch (priceLevel) {
      case 'Price A': return 'PriceA';
      case 'Price B': return 'PriceB';
      case 'Price C': return 'PriceC';
      case 'Retail Price': return 'RetailPrice';
      case 'Minimum Price': return 'MinPrice';
      default: return 'ActiveePrice';
    }
  }
}
