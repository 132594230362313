import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TsysCardType, TsysManifest } from './tsys.model';
import { NgxAimService } from '../../../ngx-aim.service';
import { TransactionType } from '../../../enums';

@Injectable({
  providedIn: 'root'
})
export class NgxAimTsysService {
  http = inject(HttpClient);
  ngxAimService = inject(NgxAimService);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/payment/tsys`;

  generateManifest(transactionType: TransactionType): Observable<TsysManifest> {
    return this.http.get<TsysManifest>(`${this.baseUrl}/generate-manifest`, {
      params: new HttpParams()
        .set('transactionType', transactionType)
    });
  }

  getCardType(cardType: TsysCardType): string {
    switch (cardType) {
      case 'J': return 'JCB';
      case 'M': return 'Mastercard';
      case 'R': return 'Discover';
      case 'U': return '';
      case 'V': return 'VISA';
      case 'X': return 'American Express';
    }
  }
}
