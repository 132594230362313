import { Component, inject, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { NgxAimAdminEditContractFormInputComponent } from './edit-contract-form-input/ngx-aim-admin-edit-contract-form-input.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxAimAdminPrimengModule } from '../../ngx-aim-admin.primeng.module';
import { AppLazyLoadEvent, ContractFormInput } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { MatchModesPrime } from '../../../../enums';
import { NgxAimAdminContractsService } from '../ngx-aim-admin-contracts.service';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-admin-contract-form-input',
  standalone: true,
  imports: [FormsModule, NgxAimAdminPrimengModule, AsyncPipe, NgClass],
  templateUrl: './ngx-aim-admin-contract-form-input.component.html',
  styleUrls: ['./ngx-aim-admin-contract-form-input.component.scss', '../../admin.scss'],
  providers: [DialogService, ConfirmationService]
})
export class NgxAimAdminContractFormInputComponent {
  entityService = inject(NgxAimAdminContractsService);
  dialogService = inject(DialogService);
  confirmationService = inject(ConfirmationService);
  httpErrorService = inject(HttpErrorService);
  messageService = inject(MessageService);

  @ViewChild('dt') table: Table | undefined;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedData: ContractFormInput[] = [];
  selectedOptions: string[] = [];
  showOptions = false;

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  onNewClick() {
    this.openEntity();
  }

  openEntity(entityId?: number) {
    const dialog = this.dialogService.open(NgxAimAdminEditContractFormInputComponent, {
      data: entityId,
      header: 'Contract Form input',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }

  onDeleteClick(event: Event): void {
    this.confirmationService.confirm({
      key: 'onDeleteClick',
      target: event.target!,
      message: `Are you sure that you want to delete (${this.selectedData.length}) ${this.singleOrPluralRecords}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelectedData();
      }
    });
  }

  deleteSelectedData() {
    const ids = this.selectedData.map(data => data.id) as number[];
    this.entityService.delete(ids).subscribe({
      next: (affectedRows) => this.deleteSuccess(affectedRows),
      error: (err) => this.deleteError(err, 'Could not delete records')
    });
  }

  deleteSuccess(affectedRows: number) {
    if (affectedRows === this.selectedData.length) {
      this.messageService.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: `You have deleted (${this.selectedData.length}) ${this.singleOrPluralRecords}`,
        key: 'app-toast',
        life: 5000
      });
      this.selectedData = [];
      this.onLazyLoad(this.lastLazyLoadEvent);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Some records couldn't be deleted`,
        key: 'app-toast',
        life: 5000
      });
      this.selectedData = [];
      this.onLazyLoad(this.lastLazyLoadEvent);
    }
  }

  deleteError(err: any, summary: string) {
    this.httpErrorService.onHttpError(err, summary);
  }

  onOptionsClose(action: 'cancel' | 'update') {
    if (action === 'cancel') {
      this.selectedOptions = [];
    }
    if (action === 'update') {
      this.onLazyLoad(this.lastLazyLoadEvent);
    }
    this.showOptions = false;
  }

  get singleOrPluralRecords(): string {
    return `record${this.selectedData.length > 1 ? 's' : ''}`;
  }
}
