import { inject, Injectable } from '@angular/core';
import { FilterChip, FilterChipType, FindAllResult, ItemFilterDto, PriceFilter, ItemForDisplay, WebCategoryJsonAttribute } from '../models';
import { ItemPageSizes, ItemSortBy } from '../enums/item-filter.enum';
import { BehaviorSubject, Observable, catchError, finalize, of, Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from './http-error.service';
import { NgxAimService } from '../ngx-aim.service';
import { CatForDisplay, SelectedAttribute, SubCatsAndAttributes } from '../models/attribute.model';

@Injectable({
  providedIn: 'root'
})
export class ItemFilterService {
  http = inject(HttpClient);
  httpErrorService = inject(HttpErrorService);
  ngxAimService = inject(NgxAimService);

  private baseUrl = `${this.ngxAimService.getApiUrl()}/items`;

  private rowsSubject = new BehaviorSubject<number>(ItemPageSizes[0]);
  public rows$ = this.rowsSubject.asObservable();
  public setRows(rows: number) {
    this.rowsSubject.next(rows);
  }

  private firstSubject = new BehaviorSubject<number>(0);
  public first$ = this.firstSubject.asObservable();
  public setFrist(first: number) {
    this.firstSubject.next(first);
  }

  private sortBySubject = new BehaviorSubject<ItemSortBy>(ItemSortBy.NEWEST);
  public sortBy$ = this.sortBySubject.asObservable();
  public setSortBy(sortBy: ItemSortBy) {
    this.sortBySubject.next(sortBy);
  }

  private manufacturerSubject = new BehaviorSubject<string>('');
  public manufacturer$ = this.manufacturerSubject.asObservable();
  public setManufacturer(manufacturer: string) {
    this.manufacturerSubject.next(manufacturer);
    this.removeChips('manufacturer');
    if (manufacturer) {
      this.addChip({
        type: 'manufacturer',
        name: manufacturer,
        value: manufacturer,
      });
    }
  }

  private categorySubject = new BehaviorSubject<number | null>(null);
  public category$ = this.categorySubject.asObservable();
  public setCategory(id: number) {
    if (this.categorySubject.value === id) {
      return;
    }
    this.categorySubject.next(id);
    this.setSubCatsAndAtts(id);
  }

  private selectedAttributesSubject = new BehaviorSubject<SelectedAttribute[]>([]);
  public selectedAttributes$ = this.selectedAttributesSubject.asObservable();
  public async setSelectedAttributes(attributes: SelectedAttribute[]) {
    this.selectedAttributesSubject.next(attributes);

    if (attributes.length && !this.attributesSubject.value.length) {
      await this.waitForAttributes();
    }
    this.removeChips('attribute');

    attributes.forEach((att) => {
      this.addChip({
        type: 'attribute',
        name: att.Description,
        value: att.selectedValue,
      });
    });
  }

  private attributesSubject = new BehaviorSubject<WebCategoryJsonAttribute[]>([]);
  public attributes$ = this.attributesSubject.asObservable();
  private subCategoriesSubject = new BehaviorSubject<CatForDisplay[]>([]);
  public subCategories$ = this.subCategoriesSubject.asObservable();
  setSubCatsAndAtts(categoryId: number | null) {
    this.getSubCatsAndAtts(categoryId).subscribe({
      next: (CatAtt) => {
        this.attributesSubject.next(CatAtt.jsonData.Attributes);
        this.subCategoriesSubject.next(CatAtt.categories);
      }
    });
  }

  private minMaxPriceSubject = new BehaviorSubject<PriceFilter>({ min: null, max: null });
  public minMax$ = this.minMaxPriceSubject.asObservable();
  public setMinMax(minMax: PriceFilter) {
    this.minMaxPriceSubject.next(minMax);
  }

  private searchTermSubject = new BehaviorSubject<string>('');
  public setSearchTerm(term: string) {
    this.searchTermSubject.next(term);
    this.removeChips('searchTerm');
    if (term) {
      this.addChip({ type: 'searchTerm', value: term });
    }
  }

  private filterChipsSubject = new BehaviorSubject<FilterChip[]>([]);
  public filterChips$ = this.filterChipsSubject.asObservable();
  addChip(chip: FilterChip) {
    const chips = this.filterChipsSubject.value;
    chips.push(chip);
    this.filterChipsSubject.next(chips);
  }

  removeChips(type: FilterChipType) {
    const chips = this.filterChipsSubject.value.filter(chip => chip.type !== type);
    this.filterChipsSubject.next(chips);
  }

  private showSubCatImagesSubject = new BehaviorSubject<boolean>(false);
  public showSubCatImages$ = this.showSubCatImagesSubject.asObservable();
  public setShowSubCatImages(show: boolean) {
    this.showSubCatImagesSubject.next(show);
  }

  private dataSubject = new BehaviorSubject<ItemForDisplay[]>([]);
  public data$ = this.dataSubject.asObservable();

  private dataCountSubject = new BehaviorSubject<number>(0);
  public dataCount$ = this.dataCountSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  public onFilterEvent(): void {
    this.isLoadingSubject.next(true);
    this.dataSubject.next([]);
    this.findAll().pipe(
      catchError((err) => {
        this.httpErrorService.onHttpError(err, 'Could not retrieve products');
        return of([]);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    ).subscribe(res => {
      this.dataSubject.next(res[0]);
      this.dataCountSubject.next(res[1]);
    });
  }

  private findAll(): Observable<FindAllResult<ItemForDisplay>> {
    const dto: ItemFilterDto = {
      rows: this.rowsSubject.value,
      first: this.firstSubject.value,
      sortBy: this.sortBySubject.value,
      category: this.categorySubject.value,
      minMax: this.minMaxPriceSubject.value,
      searchTerm: this.searchTermSubject.value,
      attributes: this.selectedAttributesSubject.value,
      manufacturer: this.manufacturerSubject.value,
    }
    return this.http.post<FindAllResult<ItemForDisplay>>(`${this.baseUrl}/all`, dto);
  }

  private getSubCatsAndAtts(categoryId: number | null): Observable<SubCatsAndAttributes> {
    return this.http.get<SubCatsAndAttributes>(`${this.ngxAimService.getApiUrl()}/web-categories/attributes/${categoryId}`);
  }

  private waitForAttributes(): Promise<void> {
    const attReceived = new Subject<void>();
    return new Promise<void>((resolve) => {
      this.attributesSubject.pipe(takeUntil(attReceived)).subscribe((atts => {
        if (atts.length) {
          attReceived.next();
          attReceived.complete();
          resolve();
        }
      }));
    });
  }
}
