import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { ListboxClickEvent } from 'primeng/listbox';
import { NgxAimAdminEditSubCategoryComponent } from './edit-sub-category/ngx-aim-admin-edit-sub-category.component';
import { NgxAimAdminPrimengModule } from '../../ngx-aim-admin.primeng.module';
import { TrueFalseText } from '../../../../types';
import { AppUploadEvent, Category, SubCategory, UpdateCateogryDto } from '../../../../models';
import { HttpErrorService, UtilsService } from '../../../../services';
import { NgxAimAdminCategoryService } from '../ngx-aim-admin-category.service';

@Component({
  selector: 'ngx-aim-admin-edit-category',
  standalone: true,
  imports: [ReactiveFormsModule, FileUploadModule, NgxAimAdminPrimengModule],
  templateUrl: './ngx-aim-admin-edit-category.component.html',
  styleUrls: ['./ngx-aim-admin-edit-category.component.scss', '../../admin.scss'],
  providers: [DialogService],
})
export class NgxAimAdminEditCategoryComponent implements OnInit {
  entityService = inject(NgxAimAdminCategoryService);
  fb = inject(FormBuilder);
  config = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  messageService = inject(MessageService);
  httpErrorService = inject(HttpErrorService);
  utils = inject(UtilsService);
  dialogService = inject(DialogService);

  headers: any;
  dropDownOptions: TrueFalseText[] = ['True', 'False'];

  form = this.fb.group({
    CategoryNumber: this.fb.nonNullable.control<number>(0),
    Description: this.fb.control<string | null>({ value: '', disabled: true }),
    showOnMainNav: this.fb.nonNullable.control<TrueFalseText>('False'),
    thumbSrc: this.fb.control<string | null>(null),
    webTitle: this.fb.nonNullable.control<string>('', [Validators.required]),
    shortDescription: this.fb.nonNullable.control<string>(''),
  });

  subCategories: SubCategory[] = [];

  isLoading = false;
  isSaving = false;

  ngOnInit() {
    if (typeof this.config.data === 'number') {
      this.getEntity(this.config.data);
    }
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve category')
    });
  }

  setFormData(entity: Category) {
    this.isLoading = false;
    this.form.patchValue({
      ...entity,
      showOnMainNav: this.utils.convertBooleanToTrueFalseText(entity.showOnMainNav),
    });
    this.headers = { CategoryNumber: entity.CategoryNumber };
    this.subCategories = entity.subCategories;
  }

  onSave() {
    this.isSaving = true;
    const dto: UpdateCateogryDto = {
      CategoryNumber: this.form.controls.CategoryNumber.value,
      showOnMainNav: this.utils.convertTrueFalseTextToBoolean(this.form.controls.showOnMainNav.value),
      webTitle: this.form.controls.webTitle.value,
      shortDescription: this.form.controls.shortDescription.value,
    }
    this.entityService.save(dto).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save category ')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: Category) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Category saved',
      key: 'app-toast'
    });
  }

  onUpload(event: AppUploadEvent) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image Uploaded', key: 'app-toast' });
    this.form.patchValue({ thumbSrc: event.originalEvent.body.url });
  }

  onUploadError(event: any) {
    this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Image could not be uploaded', key: 'app-toast' });
  }

  onSubClick($event: ListboxClickEvent) {
    const dialog = this.dialogService.open(NgxAimAdminEditSubCategoryComponent, {
      data: { CategoryNumber: this.config.data, SubNumber: $event.option.SubNumber },
      header: 'Sub Category',
      width: '700px',
      contentStyle: { "max-height": "90%", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.getEntity(this.config.data);
    });
  }
}
