import { Component, inject, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { NgxAimAdminEditOrderComponent } from './edit-order/ngx-aim-admin-edit-order.component';
import { NgxAimAdminPrimengModule } from '../ngx-aim-admin.primeng.module';
import { AppLazyLoadEvent } from '../../../models';
import { MatchModesPrime } from '../../../enums';
import { NgxAimAdminOrderService } from './ngx-aim-admin-order.service';
import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-admin-orders',
  standalone: true,
  imports: [FormsModule, NgxAimAdminPrimengModule, NgClass, CurrencyPipe, AsyncPipe],
  templateUrl: './ngx-aim-admin-orders.component.html',
  styleUrls: ['./ngx-aim-admin-orders.component.scss', '../admin.scss'],
  providers: [DialogService],
})
export class NgxAimAdminOrdersComponent {
  entityService = inject(NgxAimAdminOrderService);
  dialogService = inject(DialogService);

  @ViewChild('dt') table: Table | undefined;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedOptions: string[] = [];
  isSyncing = false;

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  openEntity(entityId?: number) {
    const dialog = this.dialogService.open(NgxAimAdminEditOrderComponent, {
      data: entityId,
      header: 'Order',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }
}
