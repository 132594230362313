import { Component, Input, OnInit } from '@angular/core';
import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { NgxAimOverviewContractsClass } from './ngx-aim-overview-contracts.class';
import { AccessoryImagePipe } from '../../../pipes';

@Component({
  selector: 'ngx-aim-overview-contracts',
  standalone: true,
  imports: [
    ButtonModule,
    DataViewModule,
    DividerModule,
    DialogModule,
    CardModule,
    ReactiveFormsModule,
    InputTextModule,
    NgClass,
    CurrencyPipe,
    AccessoryImagePipe,
    AsyncPipe,
  ],
  templateUrl: './ngx-aim-overview-contracts.component.html',
  styleUrls: ['./ngx-aim-overview-contracts.component.css']
})
export class NgxAimOverviewContractsComponent extends NgxAimOverviewContractsClass implements OnInit {
  @Input({ required: true })
  override completeUrl: string = '';

  ngOnInit() {
    this.init();
  }
}
